import { render, staticRenderFns } from "./TaskViewBadgeFilter.vue?vue&type=template&id=ad646e6c"
import script from "./TaskViewBadgeFilter.vue?vue&type=script&lang=js"
export * from "./TaskViewBadgeFilter.vue?vue&type=script&lang=js"
import style0 from "./TaskViewBadgeFilter.vue?vue&type=style&index=0&id=ad646e6c&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/janustest/.jenkins/workspace/05_Client_Vue_Release_Pipeline/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ad646e6c')) {
      api.createRecord('ad646e6c', component.options)
    } else {
      api.reload('ad646e6c', component.options)
    }
    module.hot.accept("./TaskViewBadgeFilter.vue?vue&type=template&id=ad646e6c", function () {
      api.rerender('ad646e6c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Filter/TaskViewBadgeFilter.vue"
export default component.exports