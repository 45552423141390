<template>
    <div :id="id" class="task-view-badge-filter filter-list" style="width: 100%" :style="{ '--task-view-badge-filter-group-height': badgeGroupHeight }">
      <div class="filter-label">
        <label class="ml-2">{{ $t(`staff.filters`, [filterData.length]) }}</label>
      </div>
      
      <button :id="`BADGE_FILTER_ADD_${id}`" class="add-filter-btn btn-action" @click="filterAdd"><font-awesome-icon :icon="['far', 'plus']"/>
        <b-popover
          :target="`BADGE_FILTER_ADD_${id}`"
          placement="top"
          boundary="viewport"
          triggers="hover"
          :content="$t('button.filter_add')">
        </b-popover>
      </button>
      
     <BadgeGroup v-model="filterData" :class="{ 'task-view-badge-pin-appended': canPin }">
        <template v-slot:default="{ item, index }">
          <Badge @badgeRemove="badgeRemove(index)"
            @badgeClick="badgeClick(index)"
            :text="item != null? item.name : ''" 
            variant="primary" 
            :pillable="item == null || !!item.pillable" :key="`${index}`"
            enableTooltip
           />
        </template>
      </BadgeGroup>
  
      <template v-if="canPin">
        <b-btn :id="`BTN_PIN_BADGE_FILTER_${id}`" @click="onPin" class="search-append search-append-bg badge-filter-pin-btn" size="sm">
          <font-awesome-icon variant="secondary" :icon="['far', 'thumbtack']"/>
        </b-btn>
        <b-popover :target="`BTN_PIN_BADGE_FILTER_${id}`" triggers="hover" placement="top" boundary="viewport">
          {{ $t( pinned? 'button.unpinFromToolbar' : 'button.pinOnToolbar' ) }}
        </b-popover>
      </template>
      
      
      <b-modal :title="$t('filter_component.add_filter')"
          v-model="filterShow"
          :ok-title="$t('button.ok')"
          @ok="addFilter"
          content-class="shadow"
          no-close-on-backdrop
          >
        <b-form-group id="field-type" :label="$t('filter_component.filter_field')">
          <multiselect v-model="field" class="custom-dropdown-options enable-option-icon"
            :max-height="300"
            :options="fields.map(i => i.value)"
            :custom-label="getFieldOptionLabel"
            :placeholder="''"
            :searchable="false" 
            :allow-empty="false"
            :showLabels="false">
            <template slot="option" slot-scope="props">
              <font-awesome-icon class="selected-option-icon" v-if="field == props.option" :icon="['far', 'check']" />
              <span class="option__title">{{ getFieldOptionLabel(props.option) }}</span>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group>
          <template v-if="!Array.isArray(values) && !isTextField">
            <multiselect v-model="operator" class="custom-dropdown-options enable-option-icon"
              :max-height="300"
              :options="operatorsAll.map(i => i.value)"
              :custom-label="getOperatorOptionLabel"
              :placeholder="''"
              :searchable="false" 
              :allow-empty="false"
              :showLabels="false">
              <template slot="option" slot-scope="props">
                <font-awesome-icon class="selected-option-icon" v-if="operator == props.option" :icon="['far', 'check']" />
                <span class="option__title">{{ getOperatorOptionLabel(props.option) }}</span>
              </template>
            </multiselect>
          </template>
          <template v-else-if="!Array.isArray(values) && isTextField">
            <multiselect v-model="operator" class="custom-dropdown-options enable-option-icon"
              :max-height="300"
              :options="operatorsTextAll.map(i => i.value)"
              :custom-label="getOperatorTextOptionLabel"
              :placeholder="''"
              :searchable="false" 
              :allow-empty="false"
              :showLabels="false">
              <template slot="option" slot-scope="props">
                <font-awesome-icon class="selected-option-icon" v-if="operator == props.option" :icon="['far', 'check']" />
                <span class="option__title">{{ getOperatorTextOptionLabel(props.option) }}</span>
              </template>
            </multiselect>
          </template>
          <b-form-radio-group
            v-else
            id="radio-group-1"
            v-model="operator"
            :options="operators"
            name="operator"
          ></b-form-radio-group>
        </b-form-group>
        <b-form-group v-if="Array.isArray(values)" id="field-value" :label="$t('filter_component.filter_value')">
          <multiselect v-model="multiselectValue" class="custom-dropdown-options enable-option-icon multiple-mode"
            :max-height="300"
            :options="sanitizedMultiselectOptions"
            :custom-label="getMultiselectOptionLabel"
            :placeholder="$t('filter_component.search')"
            track-by="value"
            label="text"
            :multiple="true" 
            :close-on-select="false"
            :clear-on-select="false" 
            :preserve-search="true"
            :searchable="true" 
            :allow-empty="true"
            :showLabels="false"
            @select="multiselectAddEvent"
            @remove="multiselectRemoveEvent">
            <template slot="option" slot-scope="props">
              <font-awesome-icon class="selected-option-icon" v-if="isMultiselectSelected(props.option)" :icon="['far', 'check']" />
              <template v-if="field == 'color'">
                <div class="d-inline-block mr-1 color-icon" :style="{ '--option-color-code': props.option?.value != null || props.option?.value != '(Empty)'? props.option?.value : null }"></div>
              </template>
              <span class="option__title" :style="{ 'padding-left': field == 'color'? '20px' : null }">{{ getMultiselectOptionLabel(props.option) }}</span>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group v-if="!Array.isArray(values)" :label="$t('filter_component.filter_value')">
          <b-input-group v-if="isNumberField">
            <b-form-input
              v-model="fieldValues[field]" 
              type="number"
              @focusout="onNumberFocusOut" 
              @keydown="onNumberKeyDown">
            </b-form-input>
          </b-input-group>
          <b-input-group v-else-if="isTextField">
            <b-form-input
              v-model="values" 
              type="text">
            </b-form-input>
          </b-input-group>
          <b-input-group v-else-if="!isDateField">
            <b-input-group-prepend>
              <b-button id="VAL_SUBTRACT" @click.prevent="AddMinus(-1)">
                <font-awesome-icon :icon="['far', 'minus']"/>
                <b-popover
                  target="VAL_SUBTRACT"
                  placement="top"
                  triggers="hover"
                  :content="$t('task.button.duration_subtract')">
                </b-popover>
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="fieldValues[field]" @focusout="onFocusOut" @keydown="onKeyDown">
            </b-form-input>
            <b-input-group-append>
              <b-button id="VAL_ADD" @click.prevent="AddMinus(1)">
                <font-awesome-icon :icon="['far', 'plus']"/>
                <b-popover
                  target="VAL_ADD"
                  placement="top"
                  triggers="hover"
                  :content="$t('task.button.duration_add')">
                </b-popover>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-input-group v-else>
            <b-form-datepicker v-model="values" class="mb-2"
              today-button
              reset-button
              close-button
              hide-header
              boundary="viewport"
              :popper-opts="{ positionFixed: true }"
              :label-today-button="$t('date.today')"
              :label-reset-button="$t('date.reset')"
              :label-close-button="$t('date.close')"
              today-button-variant="primary"
              reset-button-variant="danger" 
              close-button-variant="secondary"
            >
              <template v-slot:button-content="{ }">
                <font-awesome-icon :icon="['far', 'calendar-days']" />
              </template>
            </b-form-datepicker>
          </b-input-group>
        </b-form-group>
        
        <b-form-group v-if="!Array.isArray(values) && operator === 'between' && !isTextField" :label="$t('filter_component.filter_value')">
          <b-input-group v-if="isNumberField">
            <b-form-input
              v-model="value2" 
              type="number"
              @focusout="onNumberFocusOut" 
              @keydown="onNumberKeyDown">
            </b-form-input>
          </b-input-group>
          <b-input-group v-else>
            <b-form-datepicker v-model="value2" class="mb-2"
              today-button
              reset-button
              close-button
              hide-header
              boundary="viewport"
              :popper-opts="{ positionFixed: true }"
              :label-today-button="$t('date.today')"
              :label-reset-button="$t('date.reset')"
              :label-close-button="$t('date.close')"
              today-button-variant="primary"
              reset-button-variant="danger" 
              close-button-variant="secondary"
            >
              <template v-slot:button-content="{ }">
                <font-awesome-icon :icon="['far', 'calendar-days']" />
              </template>
            </b-form-datepicker>
          </b-input-group>
        </b-form-group>
        <template v-slot:modal-footer="{ ok, cancel }">
          <b-form-checkbox v-if="showFilterStaff" v-model="filterStaff" style="margin-right: auto">{{$t(filterCheckboxText)}}</b-form-checkbox>
          <b-form-checkbox v-if="useLink" v-model="link" style="margin-right: auto">{{$t('task.parent_tasks')}}</b-form-checkbox>
          <b-button :disabled="field === null || (!Array.isArray(values) && values === '') || (Array.isArray(values) && values.filter(v => v.checked).length === 0)" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        </template>
      </b-modal>
  
    </div>
  </template>
  
  <script>
  import { strRandom, onDurationKeyDown, incrementDuration, invertColor } from '@/helpers';
  import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
  import Multiselect from 'vue-multiselect';
  export default {
    name: 'BadgeFilter',
    components: {
      // BadgeGroup: () => import('@/components/BadgeGroup/NonDraggableBadgeGroup'),
      BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
      Badge: () => import('@/components/BadgeGroup/components/Badge'),
      'detailLinkCellRenderer': DetailLinkCellRenderer, // eslint-disable-line vue/no-unused-components
      Multiselect
    },
    props: {
      multiple: {
        type: Boolean,
        default: true
      },
      mode: {
        type: String,
        default: 'BOTH', // ['SELECT','MANAGE','BOTH']
      },
      title: {
        type: String,
        default: 'Filter Selector'
      },
      filters: {
        type: Array,
        default: () => []
      },
      fields: {
        type: Array,
        default: () => []
      },
      fieldValues: {
        type: Object,
        default: () => {}
      },
      filterCheckboxText: {
        type: String,
        default: 'staff.filter_staff'
      },
      useLink: {
        type: Boolean,
        default: false
      },
      canPin: {
        type: Boolean,
        default: false
      },
      pinned: {
        type: Boolean,
        default: false
      }
    },
    data: function() {
      return {
        id: `FILTER_LIST_${strRandom(5)}`,
        filterData: [],
        
        disableEdit: true,
        disableDelete: true,
        disableOk: true,
        selected: [],
  
        filterId: null,
        filterShow: false,
        alertMsg: null,
  
        confirmDeleteShow: false,
        totalRecords: 0,
        listvalues: [],
        field: null,
        value: null,
        value2: null,
        operator: 'is',
        filterStaff: true,
        link: false,
        editIndex: -1,
        updateTick: 0,
        
        values: [],
        multiselectValue: [],
      };
    },
    created() {
      this.invertColor = invertColor;
      this.filterData = JSON.parse(JSON.stringify(this.filters));
      this.needSync = false;
    },
    beforeDestroy() {
      this.invertColor = null;
    },
    watch: {
      filters: function(newValue) {
        this.filterData = JSON.parse(JSON.stringify(newValue));
      },
      field: function(newValue) {
        if (newValue) {
          if (Object.hasOwn(this.fieldValues, newValue)) {
            this.values = this.fieldValues[newValue];
            if (Array.isArray(this.value)) {
              this.multiselectValue = this.values.filter(i => i.checked == true).map(i => ({ text: i.text, value: i.text }));
            } else {
              this.multiselectValue = [];
            }
          } else {
            this.$emit('fetchFieldOptions', { field: newValue });
            this.values = [];
            this.multiselectValue = [];
          }
        } else {
          this.values = [];
          this.multiselectValue = [];
        }
      },
      fieldValues: function() {
        if (this.needSync) {
          this.syncFieldValues();
          this.needSync = false;
        }
        if (this.fieldValues && this.field && Object.hasOwn(this.fieldValues, this.field)) {
          this.values = this.fieldValues[this.field];
          if (Array.isArray(this.values)) {
            this.multiselectValue = this.values.filter(i => i.checked == true).map(i => ({ text: i.text, value: i.text }));
          } else {
            this.multiselectValue = [];
          }
        }
      },
      filterShow: function(newValue) {
        if (newValue) {
          this.$emit('filterModalOpened');
          if (this.field && this.fieldValues && !Object.hasOwn(this.fieldValues, this.field)) {
            this.$emit('fetchFieldOptions', { field: this.field });
          } else {
            this.syncFieldValues();
          }
        } else {
          this.$emit('filterModalClosed');
        }
      }
    },
    computed: {      
      isNumberField() {
        return this.field === 'progress';
      },
      isDateField() {
        return this.field === 'startTime' || this.field === 'closeTime';
      },
      isTextField() {
        return this.field === 'description';
      },
      showFilterStaff() {
        const field = this.fields.find(f => f.value === this.field);
        if (field) {
          if (typeof field.filterStaff !== 'undefined') {
            return field.filterStaff;
          }
        }
        return false;
      },
      operators() {
        return [
          { text: this.$t('is'), value: 'is' },
          { text: this.$t('is_not'), value: 'is_not' }
        ]
      },
      operatorsAll() {
        return [
          { text: this.$t('is'), value: 'is' },
          { text: this.$t('is_not'), value: 'is_not' },
          { text: this.$t('less_than'), value: 'lt' },
          { text: this.$t('less_than_or_equal'), value: 'lte' },
          { text: this.$t('greater_than'), value: 'gt' },
          { text: this.$t('greater_than_or_equal'), value: 'gte' },
          { text: this.$t('between'), value: 'between' }
        ];
      },
      operatorsTextAll() {
        return [
          { text: this.$t('is'), value: 'is' },
          { text: this.$t('is_not'), value: 'is_not' },
          { text: this.$t('contains'), value: 'contains' }
        ];
      },
      filterValuesText() {
        // force update
        this.updateTick;
        
        if (this.field && Object.hasOwn(this.fieldValues, this.field)) {
          const vals = this.values.filter(f => f.checked === true);
          let text = '';
          for (const val in vals) {
            if (text.length > 0) {
              text += ', ';
            }
            text += vals[val].text.replace(/\n/g, ' / ');
          }
          return text;
        }
        return '';
      },
      showError() {
        return this.alertMsg != null;
      },
      filterTitle() {
        return this.filterId && this.filterId.indexOf('FILTER_NEW') == -1? this.$t('filter.title_detail'): this.$t('filter.title_selector');
      },
      badgeGroupHeight() {
        return this.filters.length > 1? `${(this.filters.length * 30) + 5}px`: null;
      },
      sanitizedMultiselectOptions() {
        if (Array.isArray(this.values)) {
          return this.values.map(i => ({ text: i.text.replace(/\n/g, ' / '), value: i.text }));
        }
        return [];
      }
    },
    methods: {
      dismissAlert() {
        this.alertMsg = null;
      },
      badgeRemove(index) {
        const cloneData = JSON.parse(JSON.stringify(this.filterData));
        cloneData.splice(index, 1);
        this.$emit('modified', cloneData);
      },
      badgeClick(index) {
        this.field = this.filterData[index].field;
        this.operator = typeof this.filterData[index].operator !== 'undefined' ? this.filterData[index].operator : 'is';
        if (Array.isArray(this.filterData[index].value)) {
          for (const value of this.filterData[index].value) {
            const f = this.values.filter(v => v.text === value.text);
            if (f.length !== 0) {
              f[0].checked = true;
            }
          }
        }
        else {
          this.fieldValues[this.field] = this.filterData[index].value;
        }
        this.editIndex = index;
        this.filterStaff = typeof this.filterData[index].filterStaff !== 'undefined' ? this.filterData[index].filterStaff : true;
        this.link = this.filterData[index].link;
        this.value2 = this.filterData[index].value2;
        this.filterShow = true;
      },
      getOperatorText() {
        return this.operator === 'is' ? '[Is]' : this.operator === 'is_not' ? '[Is Not]' : 
               this.operator === 'lt' ? '[Less Than]' : this.operator === 'lte' ? '[Less Than or Equal]' :
               this.operator === 'gt' ? '[Greater Than]' : this.operator === 'gte' ? '[Greater Than or Equal]' :
               this.operator === 'contains' ? '[Contains]' :
               this.operator === 'between' ? '[Between]' :
               '[Is Not]';
      },
      getName(name, value) {
        if (this.operator === 'between') {
          return `${this.getParentText()}${name} ${this.getOperatorText()}: ${value} and ${this.value2}`
        }
        return `${this.getParentText()}${name} ${this.getOperatorText()}: ${value}`;
      },
      getParentText() {
        return this.useLink && this.link ? `${this.$t('task.parent_tasks')} ` : ''
      },
      addFilter() {
        if (this.editIndex === -1) {
          this.editIndex = this.filterData.findIndex(f => 
            f.field === this.field);
        }
        const name = this.fields.find(i => i.value == this.field).text;
        
        if (Array.isArray(this.values)) {
          const value = this.values.filter(v => v.checked);
          
          if (this.editIndex === -1) {
            this.filterData.push({ 
              field: this.field,
              operator: this.operator,
              // if can filter staff then use the checkbox value otherwise true
              filterStaff: this.showFilterStaff ? this.filterStaff : true,
              link: this.useLink ? this.link : false,
              value: value,
              value2: this.value2,
              name: `${this.getParentText()}${name} ${this.getOperatorText()}: ${value.map(v => { return v.text.replace(/\n/g, ' / ') }).join(', ')}`
            });
          } else {
            this.$set(this.filterData, this.editIndex, { field: this.field,
              operator: this.operator,
              // if can filter staff then use the checkbox value otherwise true
              filterStaff: this.showFilterStaff ? this.filterStaff : true,
              link: this.useLink ? this.link : false,
              value: value,
              value2: this.value2,
              name: `${this.getParentText()}${name} ${this.getOperatorText()}: ${value.map(v => { return v.text.replace(/\n/g, ' / ') }).join(', ')}`
            });
          }
         
        }
      else {
        const value = this.values;
        
        if (this.editIndex === -1) {
          this.filterData.push({ field: this.field,
                                 operator: this.operator,
                                 // if can filter staff then use the checkbox value otherwise true
                                 filterStaff: this.showFilterStaff ? this.filterStaff : true,
                                 link: this.useLink ? this.link : false,
                                 value: value,
                                 value2: this.value2,
                                 name: this.getName(name, value)
                               });
        }
        else {
          this.$set(this.filterData, this.editIndex, { field: this.field,
                                              operator: this.operator,
                                              // if can filter staff then use the checkbox value otherwise true
                                              filterStaff: this.showFilterStaff ? this.filterStaff : true,
                                              link: this.useLink ? this.link : false,
                                              value: value,
                                              value2: this.value2,
                                              name: this.getName(name, value)
                               });
        }
      }
      
        this.field = null; // reset so that the computed value is set on edit
        this.$emit('modified', this.filterData);
      },
      filterAdd() {
        this.field = this.fields.length !== 0 ? this.fields[0].value : null;
        this.editIndex = -1;
        this.value = null;
        this.value2 = null;
        this.link = false;
        this.filterShow = true;
        this.alertMsg = null;
      },
      confirmDeleteOk() {
        this.$emit('modified', this.filterData);
      },      
      onNumberKeyDown(/** event */) {
        this.values = this.fieldValues[this.field];
      },
      onKeyDown(event) {
        onDurationKeyDown(event);
        this.values = this.fieldValues[this.field];
      },
      onNumberFocusOut() {
        this.values = this.fieldValues[this.field];
      },
      onFocusOut() {
      
        if (/^[\d,\.]+$/.test(this.values)) {//eslint-disable-line
          if (/^\d+\.$/.test(this.values)) {//eslint-disable-line
            this.fieldValues[this.field] = this.values.substr(0, this.values.length - 1);
          }
          if (parseInt(this.values) === 0) {
            this.fieldValues[this.field] = 1;   
          }
          if (parseInt(this.values) > 365) {
            this.fieldValues[this.field] = 365;   
          }
          this.fieldValues[this.field] = `${this.fieldValues[this.field]}D`;
        }
        this.values = this.fieldValues[this.field];
      },
      checkboxChanged(/**index*/) {
        this.updateTick++;
      },
      AddMinus(delta) {
        if (this.fieldValues[this.field] === "") {
          this.fieldValues[this.field] = "1D";
          return;
        }
        
        if ((parseInt(this.fieldValues[this.field]) > 1 && delta === -1) ||
            (parseInt(this.fieldValues[this.field]) < 365 && delta === 1)) {
          this.fieldValues[this.field] = incrementDuration(this.fieldValues[this.field] ? this.fieldValues[this.field] : '0D', delta);
        }
        this.values = this.fieldValues[this.field];
      },
      onPin() {
        this.$emit('pin');
      },
      syncFieldValues() {
        if (this.fieldValues != null) {
          const fValues = this.fieldValues;
          const fData = Array.isArray(this.filterData)? this.filterData : [];
          const keys = Object.keys(fValues);
          for (const k of keys) {
            if (Array.isArray(fValues[k]) && fValues[k].length > 0) {
              const optionlist = fValues[k];
              let filterList = fData.find(i => i.field === k);
              if (filterList != null && Array.isArray(filterList.value) && filterList.value.length > 0) {
                filterList = filterList.value;
              }
              if (filterList != null) {
                for (const o of optionlist) {
                  o.checked = filterList.find(i => i.text === o.text) != null;
                }
              } else {
                for (const o of optionlist) {
                  o.checked = false;
                }
              }
            }
          }
        }
      },
      getFieldOptionLabel(value) {
        return this.fields.find(i => i.value === value)?.text || value;
      },
      getOperatorOptionLabel(value) {
        return this.operatorsAll.find(i => i.value === value)?.text || value;
      },
      getOperatorTextOptionLabel(value) {
        return this.operatorsTextAll.find(i => i.value === value)?.text || value;
      },
      getMultiselectOptionLabel(value) {
        return value?.text || value
      },
      isMultiselectSelected(value) {
        return this.multiselectValue.find(i => i.value == value.value) != null;
      },
      multiselectAddEvent(value) {
        const found = this.values.find(i => i.text == value.value);
        if (found) {
          found.checked = true;
        }
      },
      multiselectRemoveEvent(value) {
        const found = this.values.find(i => i.text == value.value);
        if (found) {
          found.checked = false;
        }
      }
    }
  }
  
  
  </script>
  
  <style lang="scss">
  .task-view-badge-filter .add-filter-btn {
    color: var(--grid-toolbar-button) !important;
    margin-top: 6px;
    margin-right: 0px;
    margin-left: 0px;
    // margin-bottom: -5px;
  }
  .task-view-badge-filter.filter-list .badge-group {
    text-wrap: auto;
    max-height: var(--task-view-badge-filter-group-height, 35px);
    height: var(--task-view-badge-filter-group-height, 35px);
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
    padding-right: 3px;
    margin-bottom: -2px;
    margin-top: 3px;
  }

  .task-view-badge-filter.filter-list .badge-group .badge {
    margin-right: 5px;
    margin-left: 5px;
    display: block;
  }

  .task-view-badge-filter.filter-list .badge-group .badge:first-child {
    margin-top: 3px;
  }

  .task-view-badge-filter.filter-list .badge-group .badge:last-child {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 600px) {
    .task-view-badge-filter.filter-list .badge-group .badge-text {
      max-width: 300px;
    }
  }

  @media only screen and (max-width: 500px) {
    .task-view-badge-filter.filter-list .badge-group .badge-text {
      max-width: 200px;
    }
  }

  @media only screen and (max-width: 400px) {
    .task-view-badge-filter.filter-list .badge-group .badge-text {
      max-width: 70px;
    }
  }

  .option-bg-color {
    color: var(--option-color);
    background-color: var(--option-bg-color);
  }

  .task-view-badge-filter .search-append.badge-filter-pin-btn {
    padding-top: 11px !important;
    padding-bottom: 10px !important;
    margin-bottom: -3px !important;
    margin-top: 0px !important;
    top: 1px;
    position: relative;
    border-top: 1px solid var(--form-control-border);
    border-right: 1px solid var(--form-control-border);
    border-bottom: 1px solid var(--form-control-border);
  }

  .task-view-badge-pin-appended {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .color-icon {
    position: absolute;
    top: 5px; 
    height: 14px; 
    width: 14px; 
    background-color: var(--option-color-code); 
    border: 1px solid var(--form-control-border);
  }
  </style>