var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "task-view-badge-filter filter-list",
      staticStyle: { width: "100%" },
      style: { "--task-view-badge-filter-group-height": _vm.badgeGroupHeight },
      attrs: { id: _vm.id },
    },
    [
      _c("div", { staticClass: "filter-label" }, [
        _c("label", { staticClass: "ml-2" }, [
          _vm._v(_vm._s(_vm.$t(`staff.filters`, [_vm.filterData.length]))),
        ]),
      ]),
      _c(
        "button",
        {
          staticClass: "add-filter-btn btn-action",
          attrs: { id: `BADGE_FILTER_ADD_${_vm.id}` },
          on: { click: _vm.filterAdd },
        },
        [
          _c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } }),
          _c("b-popover", {
            attrs: {
              target: `BADGE_FILTER_ADD_${_vm.id}`,
              placement: "top",
              boundary: "viewport",
              triggers: "hover",
              content: _vm.$t("button.filter_add"),
            },
          }),
        ],
        1
      ),
      _c("BadgeGroup", {
        class: { "task-view-badge-pin-appended": _vm.canPin },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ item, index }) {
              return [
                _c("Badge", {
                  key: `${index}`,
                  attrs: {
                    text: item != null ? item.name : "",
                    variant: "primary",
                    pillable: item == null || !!item.pillable,
                    enableTooltip: "",
                  },
                  on: {
                    badgeRemove: function ($event) {
                      return _vm.badgeRemove(index)
                    },
                    badgeClick: function ($event) {
                      return _vm.badgeClick(index)
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.filterData,
          callback: function ($$v) {
            _vm.filterData = $$v
          },
          expression: "filterData",
        },
      }),
      _vm.canPin
        ? [
            _c(
              "b-btn",
              {
                staticClass:
                  "search-append search-append-bg badge-filter-pin-btn",
                attrs: { id: `BTN_PIN_BADGE_FILTER_${_vm.id}`, size: "sm" },
                on: { click: _vm.onPin },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { variant: "secondary", icon: ["far", "thumbtack"] },
                }),
              ],
              1
            ),
            _c(
              "b-popover",
              {
                attrs: {
                  target: `BTN_PIN_BADGE_FILTER_${_vm.id}`,
                  triggers: "hover",
                  placement: "top",
                  boundary: "viewport",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        _vm.pinned
                          ? "button.unpinFromToolbar"
                          : "button.pinOnToolbar"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ]
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("filter_component.add_filter"),
            "ok-title": _vm.$t("button.ok"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.addFilter },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _vm.showFilterStaff
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticStyle: { "margin-right": "auto" },
                          model: {
                            value: _vm.filterStaff,
                            callback: function ($$v) {
                              _vm.filterStaff = $$v
                            },
                            expression: "filterStaff",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t(_vm.filterCheckboxText)))]
                      )
                    : _vm._e(),
                  _vm.useLink
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticStyle: { "margin-right": "auto" },
                          model: {
                            value: _vm.link,
                            callback: function ($$v) {
                              _vm.link = $$v
                            },
                            expression: "link",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("task.parent_tasks")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled:
                          _vm.field === null ||
                          (!Array.isArray(_vm.values) && _vm.values === "") ||
                          (Array.isArray(_vm.values) &&
                            _vm.values.filter((v) => v.checked).length === 0),
                        size: "sm",
                        variant: "success",
                      },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.filterShow,
            callback: function ($$v) {
              _vm.filterShow = $$v
            },
            expression: "filterShow",
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "field-type",
                label: _vm.$t("filter_component.filter_field"),
              },
            },
            [
              _c("multiselect", {
                staticClass: "custom-dropdown-options enable-option-icon",
                attrs: {
                  "max-height": 300,
                  options: _vm.fields.map((i) => i.value),
                  "custom-label": _vm.getFieldOptionLabel,
                  placeholder: "",
                  searchable: false,
                  "allow-empty": false,
                  showLabels: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function (props) {
                      return [
                        _vm.field == props.option
                          ? _c("font-awesome-icon", {
                              staticClass: "selected-option-icon",
                              attrs: { icon: ["far", "check"] },
                            })
                          : _vm._e(),
                        _c("span", { staticClass: "option__title" }, [
                          _vm._v(_vm._s(_vm.getFieldOptionLabel(props.option))),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.field,
                  callback: function ($$v) {
                    _vm.field = $$v
                  },
                  expression: "field",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            [
              !Array.isArray(_vm.values) && !_vm.isTextField
                ? [
                    _c("multiselect", {
                      staticClass: "custom-dropdown-options enable-option-icon",
                      attrs: {
                        "max-height": 300,
                        options: _vm.operatorsAll.map((i) => i.value),
                        "custom-label": _vm.getOperatorOptionLabel,
                        placeholder: "",
                        searchable: false,
                        "allow-empty": false,
                        showLabels: false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "option",
                            fn: function (props) {
                              return [
                                _vm.operator == props.option
                                  ? _c("font-awesome-icon", {
                                      staticClass: "selected-option-icon",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                                _c("span", { staticClass: "option__title" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getOperatorOptionLabel(props.option)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3390564435
                      ),
                      model: {
                        value: _vm.operator,
                        callback: function ($$v) {
                          _vm.operator = $$v
                        },
                        expression: "operator",
                      },
                    }),
                  ]
                : !Array.isArray(_vm.values) && _vm.isTextField
                ? [
                    _c("multiselect", {
                      staticClass: "custom-dropdown-options enable-option-icon",
                      attrs: {
                        "max-height": 300,
                        options: _vm.operatorsTextAll.map((i) => i.value),
                        "custom-label": _vm.getOperatorTextOptionLabel,
                        placeholder: "",
                        searchable: false,
                        "allow-empty": false,
                        showLabels: false,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function (props) {
                            return [
                              _vm.operator == props.option
                                ? _c("font-awesome-icon", {
                                    staticClass: "selected-option-icon",
                                    attrs: { icon: ["far", "check"] },
                                  })
                                : _vm._e(),
                              _c("span", { staticClass: "option__title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getOperatorTextOptionLabel(props.option)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.operator,
                        callback: function ($$v) {
                          _vm.operator = $$v
                        },
                        expression: "operator",
                      },
                    }),
                  ]
                : _c("b-form-radio-group", {
                    attrs: {
                      id: "radio-group-1",
                      options: _vm.operators,
                      name: "operator",
                    },
                    model: {
                      value: _vm.operator,
                      callback: function ($$v) {
                        _vm.operator = $$v
                      },
                      expression: "operator",
                    },
                  }),
            ],
            2
          ),
          Array.isArray(_vm.values)
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    id: "field-value",
                    label: _vm.$t("filter_component.filter_value"),
                  },
                },
                [
                  _c("multiselect", {
                    staticClass:
                      "custom-dropdown-options enable-option-icon multiple-mode",
                    attrs: {
                      "max-height": 300,
                      options: _vm.sanitizedMultiselectOptions,
                      "custom-label": _vm.getMultiselectOptionLabel,
                      placeholder: _vm.$t("filter_component.search"),
                      "track-by": "value",
                      label: "text",
                      multiple: true,
                      "close-on-select": false,
                      "clear-on-select": false,
                      "preserve-search": true,
                      searchable: true,
                      "allow-empty": true,
                      showLabels: false,
                    },
                    on: {
                      select: _vm.multiselectAddEvent,
                      remove: _vm.multiselectRemoveEvent,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "option",
                          fn: function (props) {
                            return [
                              _vm.isMultiselectSelected(props.option)
                                ? _c("font-awesome-icon", {
                                    staticClass: "selected-option-icon",
                                    attrs: { icon: ["far", "check"] },
                                  })
                                : _vm._e(),
                              _vm.field == "color"
                                ? [
                                    _c("div", {
                                      staticClass:
                                        "d-inline-block mr-1 color-icon",
                                      style: {
                                        "--option-color-code":
                                          props.option?.value != null ||
                                          props.option?.value != "(Empty)"
                                            ? props.option?.value
                                            : null,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticClass: "option__title",
                                  style: {
                                    "padding-left":
                                      _vm.field == "color" ? "20px" : null,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getMultiselectOptionLabel(
                                        props.option
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2888263712
                    ),
                    model: {
                      value: _vm.multiselectValue,
                      callback: function ($$v) {
                        _vm.multiselectValue = $$v
                      },
                      expression: "multiselectValue",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !Array.isArray(_vm.values)
            ? _c(
                "b-form-group",
                { attrs: { label: _vm.$t("filter_component.filter_value") } },
                [
                  _vm.isNumberField
                    ? _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: { type: "number" },
                            on: {
                              focusout: _vm.onNumberFocusOut,
                              keydown: _vm.onNumberKeyDown,
                            },
                            model: {
                              value: _vm.fieldValues[_vm.field],
                              callback: function ($$v) {
                                _vm.$set(_vm.fieldValues, _vm.field, $$v)
                              },
                              expression: "fieldValues[field]",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.isTextField
                    ? _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.values,
                              callback: function ($$v) {
                                _vm.values = $$v
                              },
                              expression: "values",
                            },
                          }),
                        ],
                        1
                      )
                    : !_vm.isDateField
                    ? _c(
                        "b-input-group",
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { id: "VAL_SUBTRACT" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.AddMinus(-1)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "minus"] },
                                  }),
                                  _c("b-popover", {
                                    attrs: {
                                      target: "VAL_SUBTRACT",
                                      placement: "top",
                                      triggers: "hover",
                                      content: _vm.$t(
                                        "task.button.duration_subtract"
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            on: {
                              focusout: _vm.onFocusOut,
                              keydown: _vm.onKeyDown,
                            },
                            model: {
                              value: _vm.fieldValues[_vm.field],
                              callback: function ($$v) {
                                _vm.$set(_vm.fieldValues, _vm.field, $$v)
                              },
                              expression: "fieldValues[field]",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { id: "VAL_ADD" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.AddMinus(1)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "plus"] },
                                  }),
                                  _c("b-popover", {
                                    attrs: {
                                      target: "VAL_ADD",
                                      placement: "top",
                                      triggers: "hover",
                                      content: _vm.$t(
                                        "task.button.duration_add"
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "b-input-group",
                        [
                          _c("b-form-datepicker", {
                            staticClass: "mb-2",
                            attrs: {
                              "today-button": "",
                              "reset-button": "",
                              "close-button": "",
                              "hide-header": "",
                              boundary: "viewport",
                              "popper-opts": { positionFixed: true },
                              "label-today-button": _vm.$t("date.today"),
                              "label-reset-button": _vm.$t("date.reset"),
                              "label-close-button": _vm.$t("date.close"),
                              "today-button-variant": "primary",
                              "reset-button-variant": "danger",
                              "close-button-variant": "secondary",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function ({}) {
                                    return [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "calendar-days"],
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2621928167
                            ),
                            model: {
                              value: _vm.values,
                              callback: function ($$v) {
                                _vm.values = $$v
                              },
                              expression: "values",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          !Array.isArray(_vm.values) &&
          _vm.operator === "between" &&
          !_vm.isTextField
            ? _c(
                "b-form-group",
                { attrs: { label: _vm.$t("filter_component.filter_value") } },
                [
                  _vm.isNumberField
                    ? _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: { type: "number" },
                            on: {
                              focusout: _vm.onNumberFocusOut,
                              keydown: _vm.onNumberKeyDown,
                            },
                            model: {
                              value: _vm.value2,
                              callback: function ($$v) {
                                _vm.value2 = $$v
                              },
                              expression: "value2",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "b-input-group",
                        [
                          _c("b-form-datepicker", {
                            staticClass: "mb-2",
                            attrs: {
                              "today-button": "",
                              "reset-button": "",
                              "close-button": "",
                              "hide-header": "",
                              boundary: "viewport",
                              "popper-opts": { positionFixed: true },
                              "label-today-button": _vm.$t("date.today"),
                              "label-reset-button": _vm.$t("date.reset"),
                              "label-close-button": _vm.$t("date.close"),
                              "today-button-variant": "primary",
                              "reset-button-variant": "danger",
                              "close-button-variant": "secondary",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function ({}) {
                                    return [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "calendar-days"],
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2621928167
                            ),
                            model: {
                              value: _vm.value2,
                              callback: function ($$v) {
                                _vm.value2 = $$v
                              },
                              expression: "value2",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }